import { BackendResponse } from '../models/backendResponse';
import { DownloadBookRequest, DownloadBookResponse, ListBookResponseItem } from '../models/bookApi';
import { Axios } from './axios';

const baseUrl = '/books';

const listBooks = () => {
  return Axios.get<BackendResponse<ListBookResponseItem[]>>(`${baseUrl}`);
};

const downloadBook = ({ id }: DownloadBookRequest) => {
  return Axios.get<BackendResponse<DownloadBookResponse>>(`${baseUrl}/${id}/download`);
};

export const BookApi = {
  listBooks,
  downloadBook,
};
