import { saveAs } from 'file-saver';
import { useState } from 'react';
import { BookApi } from '../api/bookApi';
import { ListBookResponseItem } from '../models/bookApi';
import LCButton from './form/LCButton';

interface LibraryCardProps {
  book: ListBookResponseItem;
}

const LibraryCard = ({ book }: LibraryCardProps) => {
  const [loading, setLoading] = useState(false);

  const downloadBook = (bookId: number) => {
    setLoading(true);

    BookApi.downloadBook({ id: bookId }).then(
      (res) => {
        const response = res.data.data;
        const blob = new Blob([new Uint8Array(response.file.data)]);
        saveAs(blob, response.name + response.fileType);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div key={book.id} className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row md:max-w-xl bg-white">
      <img className="object-cover w-full h-96 rounded-t-lg md:h-auto md:w-48 md:rounded-none md:rounded-l-lg text-[#000]" src={book.imageUrl} alt="" />
      <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">{book.name}</h5>
        <p>{book.description}</p>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{book.author}</p>
        <p className="text-lg font-bold">
          إهداء من مركز الملك عبدالله بن عبدالعزيز للتخطيط والسياسات اللغوية.
        </p>
        <LCButton
          label="تحميل"
          buttonStyle="roundedStyle2"
          backgroundColorClass="bg-primaryLight"
          className="self-center w-1/2"
          onClick={() => downloadBook(book.id)}
          loading={loading}
          disabled={loading}
        />
      </div>
    </div>
    // <div key={book.id} className="flex shadow-lg rounded-l-lg border-t border-slate-100">
    //   <div className="bg-primaryLight rounded-r-lg w-2 shrink-0"></div>
    //   <div className="grow flex flex-col gap-8 p-5">
    //     <div className="grow flex flex-col gap-2">
    //       <p className="text-3xl font-bold">{book.name}</p>
    //       <p className="text-xl text-gray-600">{book.author}</p>
    //       <p className="text-xl text-justify ">{book?.description.length > 100 ? `${book.description.substring(100, book.description.length - 1)} .....` : book.description}</p>
    //       <p className="text-lg font-bold">
    //         إهداء من مركز الملك عبدالله بن عبدالعزيز للتخطيط والسياسات اللغوية.
    //       </p>
    //     </div>
    //     <LCButton
    //       label="تحميل"
    //       buttonStyle="roundedStyle2"
    //       backgroundColorClass="bg-primaryLight"
    //       className="self-center w-1/2"
    //       onClick={() => downloadBook(book.id)}
    //       loading={loading}
    //       disabled={loading}
    //     />
    //   </div>
    // </div>
  );
};

export default LibraryCard;
